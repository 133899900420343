import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to Post a Podcast on Instagram"
          description="Three steps to post your podcast onto Instagram in 2021 using audio clips and longer cuts on IGTV"
        />

        <article>
          <h1>How to Post a Podcast on Instagram</h1>

          <p>
            Using EchoWave you can <strong><a
              href="https://app.echowave.io/register/">Create podcast clips for instagram</a></strong>. Instagram allows video posts that are up to 60 seconds long to be posted to your feed. If possible share a video from the recording if you can, or create a waveform video:
          </p>


          <h2>
            How to Share a podcast on Instagram
          </h2>

          <ol>
            <li id='step1'>
              <strong>Select Audio snippet</strong>
              <p>Pick a soundbite from your latest episode or pull a quote to post podcast clips</p>
            </li>
            <li id='step2'>
              <strong>Add a background Image</strong>
              <p>Use a captivating image as a background to grab their attention.</p>
            </li>
            <li id='step3'>
              <strong>Choose a Waveform</strong>
              <p>A waveform layered over the background image can elevate your podcast clip to the next level.</p>
            </li>
            <li id='step4'>
              <strong>Share the Video Podcast to Instagram</strong>
              <p>Post the generated waveform video to IG! You can create multiple clips to experiment what resonates with your audience</p>
            </li>
          </ol>


          <script type="application/ld+json">{`
                            {
                                "@context": "http://schema.org",
                                "@type": "HowTo",
                                "name": "How to Post a Podcast on Instagram",
                                "description": "Steps to share a podcast to Instagram by creating a video.",
                                "image": {
                                "@type": "ImageObject",
                                "url": "https://echowave.io/images/talking.jpg",
                                "height": "610",
                                "width": "915"
                            },
                                "estimatedCost": {
                                "@type": "MonetaryAmount",
                                "currency": "USD",
                                "value": "0"
                            },
                                "tool": [{
                                "@type": "HowToTool",
                                "name": "EchoWave Account"
                            }, {
                                "@type": "HowToTool",
                                "name": "PC or Mobile Phone"
                            },
                            {
                                "@type": "HowToTool",
                                "name": "Instagram Account"
                            }
                                ],
                                "step": [
                                {
                                    "@type": "HowToStep",
                                    "name": "Select Audio snippet",
                                    "url": "https://echowave.io/how-to-post-a-podcast-on-instagram/#step1",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Pick a soundbite from your latest episode or pull a quote to post podcast clips"
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-1.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Add a background Image",
                                    "url": "https://echowave.io/how-to-post-a-podcast-on-instagram/#step2",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Use a captivating image as a background to grab their attention."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-2.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Choose a Waveform",
                                    "url": "https://echowave.io/how-to-post-a-podcast-on-instagram/#step3",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "A waveform layered over the background image can elevate your podcast clip to the next level."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-3.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Share the Video Podcast to Instagram",
                                    "url": "https://echowave.io/how-to-post-a-podcast-on-instagram/#step4",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Post the generated waveform video to IG! You can create multiple clips to experiment what resonates with your audience"
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-4.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                }
                                ],
                                "totalTime": "PT10M"
                            }`}
          </script>



          <section>
            <GetStartedOrChat cta="Post your podcast to Instagram" />
            <br />
          </section>


          <img
            data-src="/images/how-to-share-a-postact-on-instagram/share-an-audio-snippet.jpg"
            alt="share Select audio snippets"
            className="lazyload"
          />

          <p>
            Getting your content in front of the captive IG audience takes a bit of strategy and some skill. A
            coordinated attack of sorts will put small pieces of information in your listener's pockets and have them
            coming back for more.
          </p>
          <p>
            Social media users often access these platforms from their mobile phones, so they're already targeted,
            podcast listeners. Employ these tactics to use Instagram to increase your readership:
          </p>


          <h2>
            Take advantage of IGTV.
          </h2>
            <img
              data-src="/images/how-to-share-a-postact-on-instagram/igtv.jpg"
              alt="IGTV podcast instagram"
              className="lazyload"
            />

          <p>
              Instagram recently added an IGTV feature that allows users to share videos that are longer than 60
              seconds. If you already record video along with your podcast, this is a great place to offer an extended
              snippet of what’s to come. Or post a blooper reel or some spin-off content that will encourage your
              followers to listen to what you have to say.
          </p>
          <p>
              Did you know! Most of Instagram's audience access the platform daily, and one-third of devotees tune in
              several times a day, making IGTV the perfect place to share your podcast.
          </p>
          <h2>
            Episode link in your Instagram bio.
          </h2>
            <img
              data-src="/images/how-to-share-a-postact-on-instagram/episode-link-in-your-instagram-bio.jpg"
              alt="Episode link podcast Instagram"
              className="lazyload"
            />

          <p>
              Instagram is a visual platform, so your bio is the only place where you can insert a personal link on
              Instagram. Make sure that you use this since texting options (like on post captions, etc., are limited.
              Use the link to direct your listeners to a third-party site or listening platforms like Spotify or Apple
              Podcasts.
          </p>
          <p>
              Link your podcast website here, too, so that they can become better acquainted with your concept outside
              of social media. It's an excellent opportunity to introduce yourself and your podcast as it is the first
              thing that users and new (potential) followers see when they land on your profile.
          </p>
          <p>
              In every Instagram post, include a call to action for your followers to head to your bio and click the
              link. Keep the links current as you release each new episode so that your followers know where to find it
              quickly. To keep things clean and simple, you should manage your links with an external service, like
              bit.ly, so that they’re always short and to the point.
          </p>
          <h2>
            Announce your guests on your podcast
          </h2>
            <img
              data-src="/images/how-to-share-a-postact-on-instagram/announce-your-guests-on-your-podcast.jpg"
              alt="podcast guests"
              className="lazyload"
            />

          <p>
              One of the best ways to get new listeners is to host an expert or reputable source with their own
              following. Collaborating on a meaningful topic will allow you to leverage their presence on your podcast.
          </p>
          <p>
              Coordinating a blitz campaign on Instagram is simple, but if done well, it can be quite effective. You
              have several options to choose from.
          </p>
          <p>
            Create a post, using a pull quote from the episode and tag your guest
            in it. Post a short podcast clip on Instagram and tag them. Highlight a quote in the stories or post a
            screenshot of the episode as you link and tag your guest.
          </p>
          <p>
              To maximize your exposure, prepare media for your guests to post on their page, too. Coordinate the
              information so that your promotion looks cohesive. Provide an image, a quote, and a caption to help them
              spread the word to their base that they will appear on your podcast and when to expect it or announce its
              release.
          </p>
          <h2>
            Use your hashtags.
          </h2>
            <img
              data-src="/images/how-to-share-a-postact-on-instagram/use-your-hashtags.jpg"
              alt="podcast hashtags"
              className="lazyload"
            />

          <p>
              Hashtags are a great way to target your audience. By accurately tagging your posts, you can have it show
              up in places where your followers will stumble across it.
          </p>
          <p>
              The first and most important hashtag that you should worry about is the one that you create for your
              podcast. By creating your podcast’s own hashtag, you help to solidify your brand image. Use it every time
              that you post so that each of your posts will be automatically collected into one space whenever a user
              clicks on it. It will lead to every instance of that hashtag, including mentions from other posts so that
              all of the information related to your podcast is in one place.
          </p>
          <p>
              Using other hashtags requires a bit more strategy, you can reach a wider audience with hashtags, but can
              also help direct viewers to your content. Look for trending and popular topics that are related to what
              you talk about. Properly placed hashtags will allow your content to show up on Instagram’s explore pages.
              The ideal number is about 9-11 hashtags per post.
          </p>

          <h2>
            Use your stories
          </h2>
          <img
            data-src="/images/how-to-share-a-postact-on-instagram/use-your-stories.jpg"
            alt="Use your stories"
            className="lazyload"
          />

          <p>

            Instagram stories are a great way to connect with your followers, and a full third of the most-viewed
            stories have come from a business, so it’s a great way to advertise.

          </p>
          <p>

            Continue to post relevant content and create brief storey slides to tease and introduce your podcast
            episodes or use them to share behind-the-scenes footage.

          </p>
          <p>

            Keep in mind that stories only last for 24 hours. This transient nature makes them a great place to be
            more open and candid than you would be posting to your permanent feed. If you want them to remain on your
            Instagram page after they've expired, save them as a highlight that your followers can click through at
            any time.

          </p>
          <p>
            Use your stories as a way to connect with your base between podcast releases. Offer a box for questions to
            field for a Q&A, or solicit recommendations for topics that your fans want to hear more about.
          </p>
          <p>
            Once you’ve amassed 10,000 followers on a business account, you can insert a link in your story as a call
            to action that will take them straight to wherever your podcast is hosted. If you're not quite up to 10k
            followers, use your stories to guide them to a link in your bio marketing your podcast.
          </p>


          <h2>
            Curate a stunning feed
          </h2>
            <img
              data-src="/images/how-to-share-a-postact-on-instagram/curate-a-stunning-feed.jpg"
              alt="stunning podcast social feeds"
              className="lazyload"
            />
          <p>
              Once again, Instagram is a visual platform. To do the best job of promoting your podcast on it, post
              quality audio content into a beautiful, cohesive feed.
          </p>
          <p>
            Find your style and carry it through. Use what you
            post on Instagram to complement your podcast, and make it as aesthetic as possible. Post reliably and
            consistently to attract and maintain your following.
          </p>
          <h2>
            Follow other podcasters or influencers
          </h2>
            <img
              data-src="/images/how-to-share-a-postact-on-instagram/follow-other-podcasters-or-influencers.jpg"
              alt="Podcast influencers"
              className="lazyload"
            />
          <p>
              To build your own network of followers, spend time within the content creators community that your podcast
              represents by engaging with your guest's comments on recent posts. Thoughtfully Interacting with their
              content adds value to your listener base and can help expand your following.
          </p>
          <h2>
              Make it personal.
          </h2>
            <img
              data-src="/images/how-to-share-a-postact-on-instagram/make-it-personal.jpg"
              alt="personal posts"
              className="lazyload"
            />
          <p>
              Share a selfie and introduce yourself as the person behind the podcast.
            </p>

          <p>
              If the podcast is put on by a
              team, share their pictures and stories, as well as those of any guests you have. Social media and
              marketing are all about building trust, relationship, and connection. Your potential podcast listeners are
              all busy doing other things. They’ll only invest their time in your podcast if it provides them with
              useful content from people who come across as relatable and engaging.
          </p>

          <p>
              There are a variety of features and strategies that you can use on Instagram to promote your podcast.
              Coordinate your efforts under a cohesive aesthetic that will connect with and engage your user. Investing
              in your audience in each of these ways will foster the development of a fulfilling and supportive,
              sustainable listening community.
          </p>
          <p>
              While some of these ideas may work better for you than others do, feel free to experiment to see what
              captivates and engages with your particular target audience. Tweak your strategy to find what works, but
              always be consistent in your approach.
          </p>

          <section>
            <h2>FAQ</h2>
            <FAQ
              items={[
                {
                  question: 'How to post a podcast on instagram?',
                  answer:
                    'You can use EchoWave to generate a video from your podcast, that can be posted directly to Instagram.',
                },
              ]}
            />
          </section>
        </article>
      </Layout>
    )
  }
}

export default Page
