import React from 'react'
import styles from "./index.module.css";

export default class GetStartedOrChat extends React.Component{

  render() {
    const {cta} = this.props;

    return (
      <div className={styles.enterprise}>
        <p>{cta}</p>
        <div className={styles.cta}>
          <div className={styles.buyContainer}>
            <a href="https://app.echowave.io/register">
              <button className={styles.getStarted}>Get Started</button>
            </a>
          </div>
          <div className={styles.buyContainer} onClick={() => window.$crisp.push(["do", "chat:open"])}>
            <button className={styles.getStarted}>Chat with us</button>
          </div>
        </div>
      </div>
    )
  }
}